
import React, { useState, useEffect } from "react";
import "./Body.css";
import SeleccionDescuento from "./SeleccionDescuento";

const Body = ({ onRegionSeleccionada, objetoAlmacenado }) => {
  const [regiones, setRegiones] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showDescuento, setShowDescuento] = useState(false);

  const handleBotonSeleccionado = (region,regionId) => {
    setShowDescuento(true);
    onRegionSeleccionada(region, regionId);
  };

  useEffect(() => {
    fetch("https://chetito.shop/api/api_amaregalos_regiones.php")
      .then((response) => response.json())
      .then((data) => {
        setRegiones(data); // Asumiendo que la API devuelve un array de regiones
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setIsLoading(false);
      });
  }, []); // El array vacío asegura que el efecto se ejecute solo una vez al montar

  if (isLoading) {
    return (
      <div className="text-center">
        <img
          src={`${process.env.PUBLIC_URL}/cargando.png`}
          alt="Cargando"
          style={{ animation: "spin 2s linear infinite", height: "50px" }}
        />
      </div>
    );
  }

  return (
    <div className={`container mt-5`}>
      <div
        className={`container mt-5 ${showDescuento ? "deslizar-derecha" : ""}`}
      >
        <h5 className="important-margin">Selecciona la ciudad de tu entrega</h5>
        <div className={`d-flex justify-content-center align-items-center `}>
          {regiones.map((region) => (
            <button
              key={region.id}
              onClick={() => handleBotonSeleccionado(region.region, region.id)}
              className="btn custom-botones"
              style={{
                backgroundColor: "purple",
                color: "white",
              }}
            >
              {region.region}
            </button>
          ))}
        </div>
      </div>
      {showDescuento && (
  <div style={{ marginTop: '-80px' }}>
     <SeleccionDescuento objetoAlmacenado={objetoAlmacenado} /> 
  </div>
)}
    </div>
  );
};

export default Body;
