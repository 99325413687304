import React from 'react';
import './Footer.css'; // Ajusta la ruta según sea necesario


function Footer() {
  return (
<footer className="bg-dark text-white text-center py-0 mt-1 custom-footer">
  <div className="container">
    <p>© 2024 Amaregalos Todos los derechos reservados.</p>
  </div>
</footer>

  );
}

export default Footer;
