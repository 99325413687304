
import React, { useState } from 'react';
import Header from './components/Header';
import Body from './components/Body';
import Footer from './components/Footer';
import './App.css';

function App() {

  // Actualiza el estado para almacenar tanto el nombre como el ID
  const [regionSeleccionada, setRegionSeleccionada] = useState({ nombre: '-', id: null });
  // Estado adicional para almacenar el objeto que ocuparás después
  const [objetoAlmacenado, setObjetoAlmacenado] = useState({ idRegion: null });

  // Función para actualizar el estado con el nombre y el ID de la región
  const handleRegionSeleccionada = (nombre, id) => {
    setRegionSeleccionada({ nombre, id });
      // Actualiza el objeto almacenado con el id de la región seleccionada
    setObjetoAlmacenado(prevState => ({ ...prevState, idRegion: id }));
  };

    console.log(objetoAlmacenado)
  return (
    <div>
      <Header regionSeleccionada={regionSeleccionada} />
      <Body onRegionSeleccionada={handleRegionSeleccionada} objetoAlmacenado={objetoAlmacenado} />
      <Footer />
    </div>
  );
}

export default App;
