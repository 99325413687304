
import React from 'react';
import './Header.css';


const Header = ({ regionSeleccionada }) => {
  return (
    <header className="fixed-top" style={{ backgroundColor: '#7D54FC', color: '#ffff' }} >
      <div className="container">

       <div className="d-flex justify-content-between align-items-center">
           <img src={`${process.env.PUBLIC_URL}/favicon.ico`} alt="Logo" className="me-2" />
          <h2 className="text-center text-white flex-grow-1">chetito.shop</h2>
          <button className="btn btn-warning me-2 mb-2 mt-2 custom-font-size">Configura tu regalo</button>
          <img src={`${process.env.PUBLIC_URL}/arrow-down.png`} alt="Flecha hacia abajo" className="arrow-bounce" />


        </div>

        <div className="table-responsive">
          <table className="table text-center">
            <thead>
              <tr >
                <th style={{color: '#7D54FC' }}>Ciudad</th>
                <th style={{color: '#7D54FC' }}>Descuento</th>
                <th style={{color: '#7D54FC' }} >Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{regionSeleccionada.nombre}</td>
                <td>0%</td>
                <td>$0</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </header>
  );
};

export default Header;
