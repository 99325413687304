import React, { useState, useEffect } from 'react';
import "./SeleccionDescuento.css";

const SeleccionDescuento = ({ objetoAlmacenado }) => {
  const [descuentos, setDescuentos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [descuentoSeleccionado, setDescuentoSeleccionado] = useState(null); // Estado para almacenar el descuento seleccionado

  useEffect(() => {
    setIsLoading(true);
    fetch(`https://chetito.shop/api/api_amaregalos_regiondescuento.php?IDRegion=${objetoAlmacenado.idRegion}`)
      .then(response => response.json())
      .then(data => {
        setDescuentos(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error("Error fetching data: ", error);
        setIsLoading(false);
      });
  }, [objetoAlmacenado.idRegion]);

  const handleDescuentoSeleccionado = (e) => {
    setDescuentoSeleccionado(e.target.value);
  };

  if (isLoading) {
    return (
      <div className="text-center">
        <img src={`${process.env.PUBLIC_URL}/cargando.png`} alt="Cargando" style={{ animation: "spin 2s linear infinite", height: "50px" }} />
      </div>
    );
  }

  return (
    <div className="mensaje-descuento">
      <h5 style={{textAlign:'center'}}>Selecciona tu descuento</h5>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Día de entrega</th>
              <th>Descuento</th>
              <th>Seleccionar</th>
            </tr>
          </thead>
          <tbody>
            {descuentos.map((descuento) => (
              <tr key={descuento.IDRegionDescuento}>
                <td>{descuento.DiaEntrega}</td>
                <td>{descuento.Descuento}%</td>
                <td>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="descuentoSeleccionado"
                      id={`descuento-${descuento.IDRegionDescuento}`}
                      value={descuento.IDRegionDescuento}
                      onChange={handleDescuentoSeleccionado}
                      checked={descuentoSeleccionado === descuento.IDRegionDescuento.toString()}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SeleccionDescuento;
